<div class="city-list">
    <div class="city-header">
        <h5>Select City</h5>
        <button class="close-button" (click)="close()"><i class="pi pi-times"></i></button>
    </div>
    <div class="city-grid">
        <div class="city-item" *ngFor="let city of cities">
            <input type="radio" 
                [id]="'city-' + city.name"
                name="citySelection" 
                [value]="city.name" 
                [checked]="city.name === selectedCity"
                (change)="onCitySelect(city.name)">
            <label [for]="'city-' + city.name" class="city-label">
                {{ city.name }}
            </label>
        </div>
    </div>
</div>