import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VacentService } from 'src/app/core/services/vacent.service';

@Component({
  selector: 'app-location-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './location-popup.component.html',
  styleUrl: './location-popup.component.scss'
})
export class LocationPopupComponent implements OnInit {

  private modalService = inject(NgbModal);

  selectedCity: any = 'Chandigarh';

constructor( private tenent:VacentService ){

}
  ngOnInit(): void {
    this.selectedCity = localStorage.getItem('location');

  }
  cities = [
    { name: 'Miami', icon: 'assets/icons/delhi-ncr.svg' },
    { name: 'Chandigarh', icon: 'assets/icons/chandigarh.svg' },
    { name: 'Mohali', icon: 'assets/icons/mumbai.svg' },
    { name: 'Zirakpur', icon: 'assets/icons/mumbai.svg' },
    { name: 'Kharar', icon: 'assets/icons/bengaluru.svg' },
    { name: 'Panckula', icon: 'assets/icons/hyderabad.svg' },
  ];

  onCitySelect(cityName: string): void {
    console.log('Selected City:', cityName);
    localStorage.setItem('location',cityName)
    this.tenent.isPopupOpenSubject.next(true)
    this.close()
  }
  close(){
    this.modalService.dismissAll()

  }
}
