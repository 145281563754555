<div class="add-service-container">
    <div class="mb-3">
        <h4 class="modal-title text-capitalize">{{service?.serviceName}}</h4>
    </div>
    <form [formGroup]="form" (ngSubmit)="closeDialog()">
        <div class="options-container">
            <div class="list-heading">Select options</div>
            <div class="service-extra">
                <div class="service-extra-wrapper" *ngFor="let option of options; let i = index">
                    <div class="d-flex flex-column align-items-start">
                        <div class="option-price">${{ option.price | number: '1.2-2' }}</div>
                        <div class="item-name">{{ option.addOnsName }}</div>
                    </div>
                    <div class="qty-increment">
                        <button type="button" aria-label="Remove" (click)="decrementQtyService(i)">
                            <i class="pi pi-minus" style="font-size: 1rem"></i>
                        </button>
                        <input type="number" [value]="option.qty" class="qty-input" readonly />

                        <button type="button" aria-label="Add" (click)="incrementQtyService(i)">
                            <i class="pi pi-plus" style="font-size: 1rem"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div>
            @if (service?.extras?.length > 0) {
                <h6>Select Extras</h6>
                <div class="options-container">
                    <div class="service-extra">
                        <div class="service-extra-wrapper" *ngFor="let extra of service?.extras;let i = index">
                            <div class="d-flex flex-column align-items-start">
                                <div class="option-price">${{extra.price| number: '1.2-2'}}</div>
                                <div class="item-name">{{extra?.name}}</div>
                            </div>
                            <div class="qty-increment">
                                <button type="button" aria-label="Remove" type="button" (click)="decrementQty(i)">
                                    <i class="pi pi-minus" style="font-size: 1rem"></i>
                                </button>
                                <input type="number"  [value]="extra.qty" class="qty-input" readonly />
                                <button type="button" aria-label="Remove" type="button" (click)="incrementQty(i)" >
                                    <i class="pi pi-plus" style="font-size: 1rem"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div class="button-container mt-3">
            <button class="cancel-button" type="button" (click)="close()">Cancel</button>
            <button class="add-button" type="submit" *ngIf="!isEdit">Add</button>
            <button class="add-button" type="button" (click)="update()" *ngIf="isEdit">Update</button>
        </div>
    </form>
</div>