import { CommonModule } from '@angular/common';
import { Component, OnInit, ElementRef, ViewChild, inject, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ImageModule } from 'primeng/image';
import { InputTextModule } from 'primeng/inputtext';
import { VacentService } from 'src/app/core/services/vacent.service';
import { LocationPopupComponent } from './location-popup/location-popup.component';
declare var google: any;
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    AccordionModule,
    ImageModule,
    CommonModule,
    DialogModule, ButtonModule, InputTextModule
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit,OnChanges{
  @ViewChild('searchInput') searchInput!: ElementRef;
  currentPlaceholder: string = '';
  private readonly staticText = 'Search for ';
  private services: string[] = [
    '"Cleaning Services"',
    '"Plumbing Services"',
    '"Electrician Services"',
    '"AC Repair Services"'
  ];
  private currentIndex: number = 0;
  private readonly TYPING_SPEED = 50;
  private readonly PAUSE_DURATION = 2500;

  tanantList:any[]=[];
  getAllIndustries:any[]=[]
  filteredDataPest:any[]=[]
  Electrician:any[]=[];
  ApplianceRepair:any[]=[]
  HvacData:any[]=[]
  allCategories:any[]=[]
  visible: boolean = false;

  position: string = 'left';
  private modalService = inject(NgbModal);
  selectedCity: any = 'Chandigarh';
  constructor(private tenent:VacentService,private route:Router){

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedCity = localStorage.getItem('location')
  }

  
  ngOnInit(): void {
    this.selectedCity = localStorage.getItem('location')

    this.getAllTanent()
    this.getAllIndustry()
    this.startTypingAnimation()
    const isFirstVisit = localStorage.getItem('isFirstVisit');
    const location = localStorage.getItem('location');
  
    if (!isFirstVisit || !location) {
      this.showDialog('left');
      localStorage.setItem('isFirstVisit', 'true'); // Mark as visited
    }

    this.tenent.isPopupOpenSubject.subscribe({next:(res)=>{
      if(res){
        this.selectedCity = localStorage.getItem('location')
        this.getAllTanent()
      }
    }})
  }

 

  private async startTypingAnimation() {
    while (true) {
      const serviceText = this.services[this.currentIndex];
      
      // Type the service text
      for (let i = 0; i <= serviceText.length; i++) {
        this.currentPlaceholder = this.staticText + serviceText.substring(0, i);
        await this.delay(this.TYPING_SPEED);
      }
      
      // Pause at the end of the text
      await this.delay(this.PAUSE_DURATION);
      
      // Backspace only the service text
      for (let i = serviceText.length; i >= 0; i--) {
        this.currentPlaceholder = this.staticText + serviceText.substring(0, i);
        await this.delay(this.TYPING_SPEED / 2);
      }

      this.currentIndex = (this.currentIndex + 1) % this.services.length;
    }
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getAllTanent(){
    let payload = {
    "id": 0,
    "pageNumber": 0,
    "pageSize": 0,
    "searchStr": "",
    "customerUserId": "",
    "tenantId": 0,
    "isPopular": false,
    "city": "",
    "isHelpify": "Y"

    }

    this.tenent.getAllTenent(payload).subscribe({next:(res)=>{
      // this.tanantList = res?.data.filter((item: { industry: string; }) => item.industry === "Cleaning")
      // this.filteredDataPest = res?.data.filter((item: { industry: string; }) => item.industry === "Pest Control");
      // this.Electrician = res?.data.filter((item: { industry: string; }) => item.industry === "Electrician");
      // this.ApplianceRepair = res?.data.filter((item: { industry: string; }) => item.industry === "Appliance Repair");
      // this.HvacData = res?.data.filter((item: { industry: string; }) => item.industry === "HVAC");
      this.tanantList = res?.data.filter(
        (item: { industry: string; city: string }) => 
          item.industry === "Cleaning" && item.city === this.selectedCity
      );

      this.filteredDataPest = res?.data.filter(
        (item: { industry: string; city: string }) => 
          item.industry === "Pest Control" && item.city === this.selectedCity
      );

      this.Electrician = res?.data.filter(
        (item: { industry: string; city: string }) => 
          item.industry === "Electrician" && item.city === this.selectedCity
      );

      this.ApplianceRepair = res?.data.filter(
        (item: { industry: string; city: string }) => 
          item.industry === "Appliance Repair" && item.city === this.selectedCity
      );

      this.HvacData = res?.data.filter(
        (item: { industry: string; city: string }) => 
          item.industry === "HVAC" && item.city === this.selectedCity
      );
    }})
    
  }

  viewAll(item:any){
    const category = item.text?.toLowerCase().replace(/\s+/g, '-'); // Format industry as URL-friendly
    let location = localStorage.getItem('location')
    this.route.navigate([`${location}/${category}`]);
    localStorage.setItem('industries', JSON.stringify(item));
    localStorage.setItem('industriesType',item.value);

  }
  search(event:any){
    this.route.navigate(['service'])
  }

  getAllIndustry(){
    this.tenent.getIndustry(0,true).subscribe({next:(res)=>{
      this.getAllIndustries = res?.data
      // this.allCategories = res?.data.flatMap((item: { categories: string; }) =>
      //   item.categories.split(',').map(category => category.trim())
      // );
      
      this.allCategories = res?.data.flatMap((item: { categories: string; text: string }) =>
        item.categories.split(',').map((category: string) => ({
          name: category.trim(),   // Individual category name
          value: item.text.trim(), // Parent category value (item.text)
        }))
      );
      console.log(this.allCategories)
    }})
  }

  redirect(item:any){
    // Construct the dynamic route parts
    const city = item.city?.toLowerCase().replace(/\s+/g, '-'); // Replace spaces with hyphens
    const category = item.industry?.toLowerCase().replace(/\s+/g, '-'); // Replace spaces with hyphens
    const contractorName = item.companyName?.toLowerCase().replace(/\s+/g, '-'); // Replace spaces with hyphens
  
    // Navigate to the dynamic route
    this.route.navigate([`/${city}/${category}/${contractorName}`]);
    this.route.events.subscribe(() => {
      window.scrollTo(0, 0); // Scrolls to the top of the page
    });
    localStorage.setItem('selectedCleaner', JSON.stringify(item));

  }
  view(item:any){
    if(item == 'Pest Control'){
      this.route.navigate([`service/${`pest-control`}`])
    }else{
      this.route.navigate([`service/${item}`])
    }
    localStorage.setItem('industriesType',item);
  }

  showDialog(position: string) {
    this.selectedCity = localStorage.getItem('location')

    // this.position = position;
    // this.visible = true;

    const modalRef = this.modalService.open(LocationPopupComponent,{ 
      size: 'lg',
      modalDialogClass: '',
      windowClass: '',
      backdrop: 'static', // Prevent closing on outside click
      keyboard: false,    // Prevent closing on Esc key
    });
}

navigate(item:any){
const category = item.value?.toLowerCase().replace(/\s+/g, '-'); // Format industry as URL-friendly
let location = localStorage.getItem('location')
this.route.navigate([`${location}/${category}`]);
// localStorage.setItem('industries', JSON.stringify(item));
localStorage.setItem('industriesType',item.value);
}

}
