import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './core/services/auth.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes),provideAnimations(),provideHttpClient( withInterceptors([authInterceptor])), {
    provide: 'googleMapsScript',
    useFactory: () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBmA9X_qCu10YSBpTNpf34TpUPSTvZoP1w&libraries=places`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      return script;
    }
  }]
};
