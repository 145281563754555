import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { BookingSharedService } from 'src/app/core/services/booking-shared.service';

@Component({
  selector: 'app-add-service',
  standalone: true,
  imports: [FormsModule, MultiSelectModule,InputNumberModule,ReactiveFormsModule,CommonModule],
  templateUrl: './add-service.component.html',
  styleUrl: './add-service.component.scss',
  providers: [DialogService]
})
export class AddServiceComponent implements OnInit , OnChanges {
  private modalService = inject(NgbModal);
  options!: any[];
  value1: number = 0;
  selectedCities!: any[];
  @Input() service: any;
  @Input() isEdit: boolean = false;
  form!: FormGroup;
  mainData:any[]=[]
  extraData:any[]=[]
 extraQuantities: number[] = [];
  constructor(private fb: FormBuilder,private _bookingSharedService: BookingSharedService,){
    this.form = this.fb.group({
      addOnId: [[], Validators.required], // Addons selection
      extra:[''],
      qty: this.fb.array([]), // Optional extras array if you want to handle dynamic fields for extras
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.options = this.service?.addOns
    this.extraQuantities = this.service?.extras?.map(() => 0);
    
  }

  ngOnInit() {
    console.log('Received service:', this.service);
    this.options = this.service?.addOns
  }

  onQuantityChange(event: any, index: number) {
    // Ensure the value is within a reasonable range (0 to 10 in this example)
    this.extraQuantities[index] = Math.max(0, Math.min(event.value, 10));
  }

  // Calculate total for a specific extra
  getExtraTotal(extra: any, index: number): number {
    return extra?.price * this.extraQuantities[index];
  }

  // Optional: Get total of all selected extras
  getTotalExtras(): number {
    return this.service?.extras?.reduce((total:any, extra:any, index:any) => 
      total + this.getExtraTotal(extra, index), 0);
  }

  closeDialog() {
    if (true) {
      const filteredAddOns = this.service?.addOns?.filter((addon: any) => Number(addon.qty) > 0);
      // Filter extras with qty > 0
      const filteredExtras = this.service?.extras.filter((extra: any) => Number(extra.qty) > 0);
  
      // Construct the param object with filtered addons and extras
      const param = {
        ...this.service,
        addOns: filteredAddOns,
        extras: filteredExtras,
      };

      this._bookingSharedService.addProductToCart(param)

      this.modalService.dismissAll();
      // Filter addons with qty > 0
  
      console.log(param);
  
  
    } else {
      this.form.markAllAsTouched(); // Trigger validation for all controls
      return;
    }
  
    // Ensure at least one addon or extra exists with qty > 0
    if (this.mainData.length === 0 || !this.mainData.some((addon: any) => Number(addon.qty) > 0)) {
      // this.dialogRef.close(); // Uncomment this if the dialog should close in this case
      return;
    }
  
    // Re-apply filters to extras
    const filteredExtras = this.service.extras.filter((extra: any) => Number(extra.qty) > 0);
  
    // Construct the final param object
    const param = {
      ...this.service,
      addOns: this.mainData.filter((addon: any) => Number(addon.qty) > 0),
      extras: filteredExtras,
    };
  
  }

  update(){
    const filteredAddOns = this.service?.addOns?.filter((addon: any) => Number(addon.qty) > 0);
    // Filter extras with qty > 0
    const filteredExtras = this.service?.extras.filter((extra: any) => Number(extra.qty) > 0);

    // Construct the param object with filtered addons and extras
    const param = {
      ...this.service,
      addOns: filteredAddOns,
      extras: filteredExtras,
    };

    this._bookingSharedService.updateServiceInCart(param)

    this.modalService.dismissAll();
    // Filter addons with qty > 0

    console.log(param);
  }
  

  decrementQty(index: number) {
    let currentQty = +this.service.extras[index].qty;
    if (currentQty > 0) {
      this.service.extras[index].qty = currentQty - 1;
    }

  }

  incrementQty(index: number) {
    let currentQty = +this.service.extras[index].qty;
    if (currentQty < 1000000) {
      this.service.extras[index].qty = currentQty + 1;
    }
  }


  incrementQtyService(index: number) {
    if (this.options[index].qty < 1000000) {
      this.options[index].qty += 1;
    }
  }
  
  decrementQtyService(index: number) {
    if (this.options[index].qty > 0) {
      this.options[index].qty -= 1;
    }
  }
  

  getSelectedQty(option: any): number {
    const existingOption = this.selectedCities.find(
      (city) => city.addOnId === option.addOnId
    );
    return existingOption ? existingOption.qty : 0;
  }

  close(){
    this.modalService.dismissAll();
  }

  
}
