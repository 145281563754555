import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login/login.component';
import { LocationPopupComponent } from '../layout/location-popup/location-popup.component';
import { VacentService } from 'src/app/core/services/vacent.service';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private modalService = inject(NgbModal);
  mobileMenuVisible: boolean = false;
  selectedCity:any= 'Chandigarh'
  @ViewChild('addressInput') addressInput!: ElementRef;
  constructor(private tenent:VacentService){

  }
  
  ngOnInit(): void {
    this.selectedCity = localStorage.getItem('location')

    this.tenent.isPopupOpenSubject.subscribe({next:(res)=>{
      if(res){
        this.selectedCity = localStorage.getItem('location')
      }
    }})
  }


  showMobileMenu() {
    this.mobileMenuVisible = true;
  }

  openLogin() {
    // Implement login logic here
    const modalRef = this.modalService.open(LoginComponent,{ 
      size: 'lg',
      modalDialogClass: '',
      windowClass: ''
    });
  }
  openLocation(){
    const modalRef = this.modalService.open(LocationPopupComponent,{ 
      size: 'lg',
      modalDialogClass: '',
      windowClass: '',
      backdrop: 'static', // Prevent closing on outside click
      keyboard: false,    // Prevent closing on Esc key
    });
  }
}
