<div class="search-header">
    <div  class="container">
        <p-breadcrumb class="max-w-full light-breadcrumb-style" [model]="items" [home]="home" />
        <h1 class="page-title">Checkout</h1>
    </div>
</div>

<!--offers-section-->
<div class="container">
    <div class="checkout-wrapper">
        <div class="checkout-left">
            <form [formGroup]="calanderBookingForm" (ngSubmit)="submit()">
                <!--basic-details-section-->
                <div class="checkout-left-inner">
                    <div class="inner-item-wrapper">
                        <div class="form-row">
                            <div class="d-flex gap-1 flex-column">
                                <label>First Name</label>
                                <input type="text"  formControlName="firstName" placeholder="Enter First Name" class="helpify-input">
                            </div>
                            <div class="d-flex gap-1 flex-column">
                                <label>Last Name</label>
                                <input type="text" formControlName="lastName" placeholder="Enter Last Name" class="helpify-input">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="d-flex gap-1 flex-column">
                                <label>Email Address</label>
                                <input type="text" formControlName="emailAddress" placeholder="Enter Email Address" class="helpify-input">
                            </div>
                            <div class="d-flex gap-1 flex-column">
                                <label>Mobile Number</label>
                                <div class="mobile-input">
                                    <input type="text"  formControlName="mobileNo"value="+91 9816810805" class="helpify-input verify-input">
                                    <i class="icon-tick-icon"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <!--Service Address-section-->
                <div class="checkout-left-inner">
                    <div class="inner-item-wrapper">
                        <div class="inner-item-title mb-2">Service Address</div>
                        <div class="inner-input-item mb-3">
                            <input type="text" formControlName="serviceAddress" #addressInput placeholder="Address" class="helpify-input">
                        </div>
                        <div class="inner-input-item-grid">
                            <input type="text" formControlName="city" placeholder="City" class="helpify-input">
                            <input type="text" formControlName="state" placeholder="State" class="helpify-input">
                            <input type="text" formControlName="zipCode" placeholder="Pincode" class="helpify-input">
                        </div>
                    </div>
                    
                    <!--
                    <div class="inner-item-wrapper">
                        <div class="item-grid-wrapper">
                            <div class="d-flex align-items-center gap-3">
                                <i class="icon-location heading-icon"></i>
                                <div>
                                    <div class="inner-item-title">Service Address</div>
                                    <div class="booking-details-item">
                                        333 Cedar Drive, Mountain View, CA 94040
                                    </div>
                                </div>
                            </div>
                            <button class="edit-btn"><i class="icon-edit"></i>Edit</button>
                        </div>
                    </div>
                    -->
                </div>
                <!-- <button class="book-now-button w-100">Pay ${{totalCartAmt|number:'1.2-2'}}</button> -->

            </form>

            <!--Booking Details-section-->
            <div class="checkout-left-inner">
                <div class="inner-item-wrapper">
                    <div class="item-grid-wrapper">
                        <div class="d-flex align-items-center gap-3">
                            <i class="pi pi-calendar heading-icon"></i>
                            <div>
                                <div class="inner-item-title">Booking Details</div>
                                <div class="booking-details-item">
                                   {{bookingData?.bookingTimeText}}-{{bookingData?.bookingDate | date}}
                                </div>
                            </div>
                        </div>
                        <button class="edit-btn"><i class="icon-edit"></i>Edit</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column gap-4">
            <div class="checkout-right">
                <div class="form-wrapper">
                    <div class="form-items-list">
                        @for (service of selectedServices; track service) {
                            <div class="form-item">
                                <div class="form-value">
                                    <div>{{service.serviceName}}</div>
                                    <div class="price-text">${{service?.price|number:'1.2-2'}}</div>
                                </div>
                            </div>
                            <div class="d-flex flex-column gap-1">
                                <div class="text-muted d-flex align-items-start justify-content-between" *ngFor='let option of service.addOns ; index as i'>
                                    <div class="font13 text-capitalize">{{option.addOnsName}}</div>
                                    <div class="d-flex gap-2 align-items-center">
                                        <!-- <div class="font13 font400">${{option.price}}</div> -->
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let extraItem of service?.extras">
                                <div class="extras-span font-muted" *ngIf="extraItem.qty > 0">
                                    <div class="font14 font400 text-capitalize">
                                        {{extraItem.name}}
                                    </div>
                                    <div class="d-flex align-items-center gap-3">
                                        <div class="font13 font400">
                                            ${{extraItem.price | number: '1.2-2'}} x {{extraItem.qty}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <div class="cart-sub-total">
                            <!-- <div class="form-value">
                                <div>Discount</div>
                                <div class="price-text">$20.00</div>
                            </div> -->
                            <div class="form-value">
                                <div>Sub Total</div>
                                <div class="price-text">${{subTotal|number:'1.2-2'}}</div>
                            </div>
                            <div class="form-value">
                                <div>Tax</div>
                                <div class="price-text">${{taxAmount|number:'1.2-2'}}</div>
                            </div>
                        </div>
                        <div class="cart-total">
                            <div class="form-value">
                                <div>Total</div>
                                <div>${{totalCartAmt|number:'1.2-2'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center gap-2">
                        <button class="book-now-button w-100" type="button" (click)="submit()">Pay ${{totalCartAmt|number:'1.2-2'}}</button>
                        <div class="tc-text">By continuing, you agree to our <span>T & C</span>, <span>Privacy Policy</span></div>
                    </div>
                </div>
            </div>
            <div class="cancellation-policy-wrapper">
                <div class="cancellation-title">Cancellation Policy</div>
                <div class="cancellation-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
            </div>
        </div>
    </div>
</div>