import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SearchComponent } from "../search/search.component";
import { filter } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, RouterOutlet, SearchComponent,CommonModule],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss'
})
export class HomepageComponent implements OnInit {
  showLayout: boolean = true;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      const hideRoutes = ['/login',]; // Add more routes to hide header/footer
      this.showLayout = !hideRoutes.includes(event.urlAfterRedirects);
    });
  }
}
