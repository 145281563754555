import { Component, inject, OnInit } from '@angular/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { VacentService } from 'src/app/core/services/vacent.service';
import { filter, first } from 'rxjs';
import { environment } from '@environments/environment';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddServiceComponent } from '../add-service/add-service.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { BookingSharedService } from 'src/app/core/services/booking-shared.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { CarouselModule } from 'primeng/carousel';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
interface ReviewStat {
  rating: number;
  count: number;
  percentage: number;
}

interface BreadcrumbItem {
  label: string;
  routerLink?: string;
}

@Component({
  selector: 'app-help-page',
  standalone: true,
  imports: [
    BreadcrumbModule,
    TabViewModule,
    AccordionModule,
    CommonModule,
    DialogModule, ButtonModule,
    AddServiceComponent,
    CarouselModule
],
  templateUrl: './help-page.component.html',
  styleUrl: './help-page.component.scss',
  providers: [DialogService]
})
export class HelpPageComponent implements OnInit  {
  private modalService = inject(NgbModal);
  selectedService: any;
  cleaner:any
  images: string[] = [];
  rating: number = 4.5;  // Set your default rating
  ratingCount: number = 52;  // Set your default count
  reviewStats: ReviewStat[] = [
    { rating: 5, count: 30, percentage: 75 },
    { rating: 4, count: 12, percentage: 15 },
    { rating: 3, count: 6, percentage: 8 },
    { rating: 2, count: 3, percentage: 4 },
    { rating: 1, count: 1, percentage: 1 }
  ];
  items = [
    { label: 'Service Detail',routerLink: '/'}
  ];
  itemsData:any[]=[]
  
  home = { icon: 'pi pi-home', routerLink: '/' };
  keywords: any[] = [];

  totalCartAmt: number = 0;
  taxPer: any = 0;
  originalSubTotal: number = 0; 
  subTotal: number = 0.00;
  estimateTotal: number = 0.00;
  taxAmount: number = 0.00;
  isAdded: any = [];
  selectedServices: any[] = [];
  isEnableNextBtn: boolean = false;
  apiUrl = environment.apiUrl
  currentTime: string = ''; // Current time as string
  isOpen: boolean = false; // Status of the shop
  openTime: string = '09:00am';
  closeTime: string = '06:00pm';
  galleryImages = [
    {
      url: 'https://images.unsplash.com/photo-1581578731548-c64695cc6952',
      alt: 'Professional cleaning'
    },
    {
      url: 'https://images.unsplash.com/photo-1584820927498-cfe5211fd8bf',
      alt: 'Cleaning supplies'
    },
    {
      url: 'https://images.unsplash.com/photo-1628177142898-93e36e4e3a50',
      alt: 'Deep cleaning'
    },
    {
      url: 'https://images.unsplash.com/photo-1527515637462-cff94eecc1ac',
      alt: 'Window cleaning'
    },
    {
      url: 'https://images.unsplash.com/photo-1563453392212-326f5e854473',
      alt: 'Kitchen cleaning'
    }
  ];

  isBottomSheetVisible: boolean = false;
  reviewList:any[]=[]
  faqsList:any[]=[]
  sanitizedMapUrl!: SafeResourceUrl;

  constructor(private tenent:VacentService,private dialogService: DialogService,private _bookingSharedService: BookingSharedService,private router:Router,private sanitizer: DomSanitizer,private activatedRoute: ActivatedRoute){
   
  }

  ngOnInit(): void {


    const storedCleaner = localStorage.getItem('selectedCleaner');
    if (storedCleaner) {
      this.cleaner = JSON.parse(storedCleaner);
      console.log(this.cleaner)
      this.keywords = this.cleaner.industryCategories.split(',')
      
      // Optional: Clear after retrieving to prevent stale data
      // localStorage.removeItem('selectedCleaner');
    }
    this.loginUser()

    const mapUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.1!2d${this.cleaner.longitude}!3d${this.cleaner.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zM40zMCcwMC4wIk4gNzPCsDU0JzAwLjAiVw!5e0!3m2!1sen!2sus!4v1234567890!5m2!1sen!2sus`;
    this.sanitizedMapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl);

    this.checkOpenStatus();
    // Update status every minute
    setInterval(() => this.checkOpenStatus(), 60000);
    this.setBreadcrumb();
    // Listen for route changes and update breadcrumb
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setBreadcrumb();
      });
  }


  setBreadcrumb() {
    const urlSegments = this.router.url.split('/').filter((segment) => segment);
    this.items = [];
  
    let currentLink = '';
  
    urlSegments.forEach((segment) => {
      currentLink += `/${segment}`; // Incrementally build the full path
      this.items.push({
        label: this.capitalizeLabel(segment), // Format label
        routerLink: currentLink, // Full path for each segment
      });
    });
  
    console.log(this.items); // Debug the breadcrumb items
  }
  
  capitalizeLabel(label: string): string {
    return label
      .replace(/-/g, ' ') // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  }
  

  navigateToPreviousUrl(segments: string[]) {
    const navigateTo = '/' + segments.join('/');
    this.router.navigate([navigateTo]);
  }

  checkOpenStatus(): void {
    const currentTime = new Date();

    const openTimeInMinutes = this.convertTo24Hour(this.openTime);
    const closeTimeInMinutes = this.convertTo24Hour(this.closeTime);
    const currentTimeInMinutes =
      currentTime.getHours() * 60 + currentTime.getMinutes();

    // Compare times
    this.isOpen =
      currentTimeInMinutes >= openTimeInMinutes &&
      currentTimeInMinutes <= closeTimeInMinutes;
  }

  convertTo24Hour(time: string): number {
    if (!time) return NaN; // Guard against invalid input
    const match = time.match(/(\d+):(\d+)([ap]m)/i);
    if (!match) return NaN;

    let [hours, minutes, period] = match.slice(1);
    let hour = parseInt(hours, 10);

    if (period.toLowerCase() === 'pm' && hour !== 12) hour += 12;
    if (period.toLowerCase() === 'am' && hour === 12) hour = 0;

    return hour * 60 + parseInt(minutes, 10); // Return total minutes since midnight
  }
  loginUser(){
    this.tenent.login(this.cleaner?.emailAddress,'kwVEV6').subscribe({next:(res)=>{
      var _param = {
        id: 0,
        pageNumber: 0,
        pageSize: 0,
        searchStr: '',
      };
      this.getAll(_param);
      this.bindSelectedService()
      var param = {
        "id": 0,
        "pageNumber": 0,
        "pageSize": 0,
        "searchStr": "",
        "userId": this.cleaner?.userId
  
      }
      this.bindGrid(param);
      this.bindFaqs(param)

    }})
  }


  getAll(param:any) {
    var _totalCount = 0;
    this.tenent.getAll(param).pipe(first()).subscribe({
      next: (res) => {
        // this.loading = false;
        this.itemsData = res.data;
        this.itemsData =this.itemsData.filter((service:any) => service.status === "A");

        if (this.itemsData.length > 0) {
          this.itemsData = this.itemsData.map((element:any) => {
            return {
              ...element,
              categoryServices: element.categoryServices.filter(
                (subElement: any) => subElement.isOnlineBooking === true
              ),
            };
          });
          this.itemsData = this.itemsData.filter(function (event:any) {
            return event.categoryServices.length > 0;
          });

          this.itemsData.forEach(function (item: any) {
            item.categoryServices.forEach(function (service: any) {
              _totalCount += service.categoryServiceId;
              // if (service.uploadedFiles.length > 0) {
              //   service.uploadedFiles[0].filePath =
              //     environment.apiUrl +
              //     '/' +
              //     service.uploadedFiles[0].filePath;
              // }
              service.filePath = environment.apiUrl + '/' + service.filePath;
            });
          });
          // this.activeCategory = this.items[0].categoryId;
          // this.isAdded = new Array(_totalCount);
        }
      },
      error: (error) => {
        // this.loading = false;
      },
    });
  }

  addData(data:any){
    const exists = this.selectedServices.some(
      (item: any) => item.categoryServiceId === data?.categoryServiceId
    );

    const resetData = {
      ...data,
      addOns: data.addOns?.map((addon: any) => ({ ...addon, qty: 0 })) || [],
      extras: data.extras?.map((extra: any) => ({ ...extra, qty: 0 })) || []
    };
  
    if (exists) {
      // this._snackBar.open('Service already added', '', { duration: 3000 });
      alert('Service already added');
      return;
    }
    const modalRef = this.modalService.open(AddServiceComponent,{ 
      size: 'lg',
      modalDialogClass: '',
      windowClass: '',
      backdrop: 'static'
    });
    modalRef.componentInstance.service = resetData;
    // this.selectedService = data
  }

  visible: boolean = false;

  showDialog(service:any) {
    this.visible = true;
    this.selectedService = service;
  }

  bindSelectedService() {
    this._bookingSharedService.getProducts().subscribe((res: any[]) => {
      console.log(res); // Log the response to check its structure
      this.isAdded = [];
      let totalCartAmount = 0;
      
      if (res.length > 0) {
        res.forEach((item) => {
          let itemTotal = 0;  // Calculate total for each individual item (service)
  
          // Calculate total price for addOns of this service
          if (item.addOns && item.addOns.length > 0) {
            item.addOns.forEach((addOn: any) => {
              itemTotal += addOn.price * addOn.qty;  // Add the price of each addOn
            });
          }
  
          // Calculate total price for extras of this service (considering qty)
          if (item.extras && item.extras.length > 0) {
            item.extras.forEach((extra: any) => {
              if (Number(extra.qty) > 0) {
                itemTotal += extra.price * extra.qty;  // Add price * qty for each extra
              }
            });
          }
  
          totalCartAmount += itemTotal;  // Add this service's total to the cart total
        });
        
        
        // Assign calculated amounts to the corresponding properties
        this.originalSubTotal = totalCartAmount;
        this.subTotal = totalCartAmount;
        this._bookingSharedService.getTax('TAX').subscribe(res => {
          if (res.data != null) {
            this.taxPer = res.data.tax;
            this.taxAmount = (this.subTotal * this.taxPer) / 100; // Ensure taxPer is defined
            this.totalCartAmt = this.subTotal + this.taxAmount;
          }
        })
        this.selectedServices = res  
        // Set button state
        this.isEnableNextBtn = true;
        for (let i = 0; i < this.selectedServices.length; i++) {
          this.isAdded[this.selectedServices[i].serviceId] = true;
        }
      } else {
        this.isEnableNextBtn = false;
        this.selectedServices = [];
        this.totalCartAmt = 0; // Reset totals if no items
        this.subTotal = 0;
        this.taxAmount = 0;
      }
    });
  }

  removeExtra(serviceId: number, extraId: number, services: any) {
    // Set quantity to 0 for the selected extra
    services.extras.forEach((extra: any) => {
      if (extra.extraId === extraId) {
        extra.qty = 0;
      }
    });
  
    // Find the service that matches the given serviceId
    const service = this.selectedServices.find(s => s.categoryServiceId === serviceId);
  
    if (service && service.extras) {
      // Filter out the extra that matches the given extraId within that service
      service.extras = service.extras.filter((extra: any) => extra.extraId !== extraId);
      
      // Call the service to remove the specific extra from the cart, passing both extraId and serviceId
      this._bookingSharedService.removeProductFromCartExtras1(extraId, serviceId);
      
      // Optionally update the bound services after removing
      this.bindSelectedService();
    }
  }
  removeAddons(categoryId:any,services:any) {

    this._bookingSharedService.removeAdminProduct(categoryId);
    services.extras.forEach((extra:any) => {
      extra.qty = 0;
    });
    // this.removeAddonss(serviceIndex,addOnIndex)
  
    this.bindSelectedService()

    // const message = `Are you sure you want to remove?`;
    // const dialogData = new ConfirmDialogModel("Confirmation", message);
    // const dialogRef = this._dialog.open(ConfirmDialogComponent, {
    //   maxWidth: "400px",
    //   data: dialogData
    // });
    // dialogRef.afterClosed().subscribe(dialogResult => {
    //   if (dialogResult) {
     
    //   }
    //   else {
    //     return;
    //   }
    // });
  }

  editService(categoryId: any, services: any) {
    const modalRef = this.modalService.open(AddServiceComponent, { 
      size: 'lg',
      modalDialogClass: '',
      windowClass: '',
      backdrop: 'static'
    });
    
    // Pass the service data and flag for edit mode
    modalRef.componentInstance.service = services;
    modalRef.componentInstance.isEdit = true; // Send a flag for edit mode
  }
  

  bookNow(){
    if(this.selectedServices.length > 0){
      // this.route.navigate(['checkout'])
      const modalRef = this.modalService.open(LoginComponent,{ 
        size: 'lg',
        modalDialogClass: '',
        windowClass: ''
      });
    }
  }

  openBottomSheet() {
    this.isBottomSheetVisible = true;
  }

  bindGrid(param: any) {
    this.tenent.getAllReviews(param).subscribe(res => {
      this.reviewList = res.data;
      // this.loading = false;
    });
  }
  bindFaqs(param: any) {
    this.tenent.getAllFAQ(param).subscribe(res => {
      this.faqsList = res.data;
      // this.loading = false;
    });
  }
}
