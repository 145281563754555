import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BookingSharedService } from 'src/app/core/services/booking-shared.service';
import { VacentService } from 'src/app/core/services/vacent.service';

declare const google: any;

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    BreadcrumbModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent implements OnInit {
  items: any[] = [];
  home: any = { icon: 'pi pi-home', routerLink: '/' };
  calanderBookingForm!: FormGroup;
  bookingData: any;
  latlong: any
  totalCartAmt: number = 0;
  taxPer: any = 0;
  originalSubTotal: number = 0; 
  subTotal: number = 0.00;
  estimateTotal: number = 0.00;
  taxAmount: number = 0.00;
  isAdded: any = [];
  selectedServices: any[] = [];
  isEnableNextBtn: boolean = false;
  @ViewChild('addressInput') addressInput!: ElementRef;
  customerData:any
  map: any;
  marker: any;
  constructor( private tenent:VacentService,private formBuilder:FormBuilder,private _bookingSharedService: BookingSharedService,) {
    this.items = [
      { label: 'Shopping Cart' },
      { label: 'Checkout' }
    ];
  }
  ngOnInit(): void {
    this.bookingData = history.state;
    console.log(this.bookingData)
    this.calanderBookingForm = this.formBuilder.group({
      customerId: ['', null],
      customerAddressId: ['', null],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailAddress: ['', [Validators.required]],
      mobileNo: ['', [Validators.required]],
      serviceAddress: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', [Validators.required, Validators.pattern('[0-9]{5,6}')]],
      latitude: ['', null],
      longitude: ['', null]
    });

    this.tenent.findCustomerByMobileNo(this.bookingData.mobileNo,true).subscribe({next:(res)=>{
      this.customerData = res?.data?.result
      this.calanderBookingForm.patchValue({...res?.data?.result,mobileNo:res?.data?.result.mobileNumber})
    }})
    this.bindSelectedService()
  }

  ngAfterViewInit(): void {
    this.initializeAutocomplete();
  }


  initializeAutocomplete(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addressInput.nativeElement,
      {
        types: ['geocode'],
        componentRestrictions: { country: 'IN' },
      }
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;

      if (place.geometry) {
        const location = place.geometry.location;
        console.log(place.formatted_address)
        this.calanderBookingForm.patchValue({
          serviceAddress: place.formatted_address,
          city: this.getAddressComponent(addressComponents, 'locality'),
          state: this.getAddressComponent(
            addressComponents,
            'administrative_area_level_1'
          ),
          zipCode: this.getAddressComponent(addressComponents, 'postal_code'),
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });
      }
    });
  }

  getAddressComponent(components: any[], type: string): string {
    const result = components.find((component) =>
      component.types.includes(type)
    );
    return result ? result.long_name : '';
  }
  

  submit(){
    const allAddOns: any = [];
    const allExtras: any = [];
    this.selectedServices.forEach((item) => {
      if (item.addOns && item.addOns.length > 0) {
        allAddOns.push(...item.addOns);
      }
      if (item.extras && item.extras.length > 0) {
        allExtras.push(...item.extras);
      }
    });
    let _param = this.calanderBookingForm.value as any;
    _param = { ..._param, ...{coupanCodeId:0,isHelpify:true,isUpFrontPaymentFlow:true, bookingDate: this.bookingData.bookingDate, timeSlot: this.bookingData.bookingTimeText, bookingDetails: allAddOns,extras:allExtras,customerId: (_param.customerId == '' ? 0 : _param.customerId), addressId: (_param.customerAddressId == '' ? 0 : _param.customerAddressId), status: 'U', serviceAddress: (_param.serviceAddress != '' && _param.serviceAddress != null ? _param.serviceAddress : this.latlong?.formatted_address), longitude: (_param.longitude != '' && _param.longitude != null ? _param.longitude.toString() : this.latlong?.geometry?.location.lng()?.toString()), latitude: (_param.latitude != '' && _param.latitude != null ? _param.latitude.toString() : this.latlong?.geometry?.location?.lat().toString())}};
    console.log(_param)
    this.tenent.createBooking(_param).subscribe({next:(res)=>{
      console.log(res)
      if (res.data.paymentLink) {
        window.location.href = res.data.paymentLink;
      }
    }})
  }

  bindSelectedService() {
    this._bookingSharedService.getProducts().subscribe((res: any[]) => {
      console.log(res); // Log the response to check its structure
      this.isAdded = [];
      let totalCartAmount = 0;
      
      if (res.length > 0) {
        res.forEach((item) => {
          let itemTotal = 0;  // Calculate total for each individual item (service)
  
          // Calculate total price for addOns of this service
          if (item.addOns && item.addOns.length > 0) {
            item.addOns.forEach((addOn: any) => {
              itemTotal += addOn.price * addOn.qty;  // Add the price of each addOn
            });
          }
  
          // Calculate total price for extras of this service (considering qty)
          if (item.extras && item.extras.length > 0) {
            item.extras.forEach((extra: any) => {
              if (Number(extra.qty) > 0) {
                itemTotal += extra.price * extra.qty;  // Add price * qty for each extra
              }
            });
          }
  
          totalCartAmount += itemTotal;  // Add this service's total to the cart total
        });
        
        
        // Assign calculated amounts to the corresponding properties
        this.originalSubTotal = totalCartAmount;
        this.subTotal = totalCartAmount;
        this._bookingSharedService.getTax('TAX').subscribe(res => {
          if (res.data != null) {
            this.taxPer = res.data.tax;
            this.taxAmount = (this.subTotal * this.taxPer) / 100; // Ensure taxPer is defined
            this.totalCartAmt = this.subTotal + this.taxAmount;
          }
        })
        this.selectedServices = res  
        // Set button state
        this.isEnableNextBtn = true;
        for (let i = 0; i < this.selectedServices.length; i++) {
          this.isAdded[this.selectedServices[i].serviceId] = true;
        }
      } else {
        this.isEnableNextBtn = false;
        this.selectedServices = [];
        this.totalCartAmt = 0; // Reset totals if no items
        this.subTotal = 0;
        this.taxAmount = 0;
      }
    });
  }
}
