import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, map } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class VacentService {
   isPopupOpenSubject = new BehaviorSubject<boolean>(false); 
  constructor(private http: HttpClient) { }
getAllTenent(data:any){
  return this.http.post<any>(`${environment.apiUrl}/api/v1/Account/GetAllTenants`, data)
  .pipe(map(res => {
      return res;
  }));
}

getIndustry(industryId:any,isHelpify:boolean){
  return this.http.get<any>(`${environment.apiUrl}/api/v1/Master/GetIndustries?industryId=${industryId}&isHelpify=${isHelpify}`)
  .pipe(map(res => {
      return res;
  }));
}


getAllTimeDay(){
  return this.http.get<any>(`${environment.apiUrl}/api/v1/Master/GetTimeOfDay`)
  .pipe(map(res => {
      return res;
  }));
}


login(username: string, password: string) {
  var _params = {
      'username': username,
      'password': password
  };
  let reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  return this.http.post<any>(`${environment.apiUrl}/api/v1/Account/Login`, JSON.stringify(_params), { headers: reqHeaders })
      .pipe(map(user => {
          localStorage.setItem('helpify', JSON.stringify(user));
          return user;
      }));
}

getAll(paginationModel: any) {
  let token:any = localStorage.getItem('helpify');
    
  token = JSON.parse(token);

  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token?.data?.token}`
  });
  return this.http.post<any>(`${environment.apiUrl}/api/v1/Category/GetAll`, paginationModel,{headers: headers })
    .pipe(map(res => {
      return res;
    }));
}

getBusinessHourByWeekDay(weekDay: number) {
  let token:any = localStorage.getItem('helpify');
  token = JSON.parse(token);
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token?.data?.token}`
  });
  return this.http.get<any>(`${environment.apiUrl}/api/v1/Tenant/GetBusinessHourByWeekDay?weekdayId=` + weekDay,{headers: headers })
    .pipe(map(res => {
      return res;
    }));
}

findCustomerByMobileNo(mobileNo: any,isHelpify:boolean = false) {
  let token:any = localStorage.getItem('helpify');
  token = JSON.parse(token);
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token?.data?.token}`
  });
  return this.http.post<any>(`${environment.apiUrl}/api/v1/Customer/FindCustomerByMobileNo?mobileNo=` + mobileNo + `&isHelpify=`+isHelpify, null,{headers: headers })
    .pipe(map(res => {
      return res;
    }));
}

createBooking(bookingInfo: any) {
  let token:any = localStorage.getItem('helpify');
  token = JSON.parse(token);
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token?.data?.token}`
  });
  return this.http.post<any>(`${environment.apiUrl}/api/v1/Booking/CreateBooking`, bookingInfo,{headers:headers})
    .pipe(map(res => {
      return res;
    }));
}

getAllReviews(data: any) {
  return this.http.post<any>(`${environment.apiUrl}/api/v1/Booking/GetAllReviews`, data)
    .pipe(map(res => {
      return res;
    }));
}
getAllFAQ(data: any) {
  return this.http.post<any>(`${environment.apiUrl}/api/v1/Tenant/GetAllFAQ`, data)
    .pipe(map(res => {
      return res;
    }));
}
}
