<div class="login-wrapper" *ngIf="!showVerification && !showCalanderSection">
    <button class="close-button" (click)="close()">
       close
    </button>
    <div class="login-title">
        <h2>Login or Signup</h2>
    </div>
    <div class="login-form">
        <div class="form-row">
            <span>+91</span>
            <input type="tel" id="phone" name="phone" pattern="[0-9]{10}" placeholder="Enter phone number" />
        </div>
        <button type="button" (click)="showVerificationSection()">Continue</button>
        <p>By continuing, you agree to our <span>Terms of Service</span> &amp; <span>Privacy Policy</span></p>
    </div>
</div>

<div class="login-verify-wrapper" *ngIf="showVerification && !showCalanderSection">
    <button class="close-button" (click)="closeDialog()">
        <i class="fas fa-times"></i>
    </button>
    <div class="login-verify-title">
        <h2>Enter Verification Code</h2>
        <p>
            We have sent a verification code to 
            <span>(485) 3944-384 <button class="edit-button" (click)="goBack()"><i class="icon-edit"></i></button></span>
        </p>
    </div>
    <div class="login-otp-form">
        <div class="form-otp-row">
                <p-inputOtp [(ngModel)]="value" [integerOnly]="true" (ngModelChange)="onOtpComplete()" />
        </div>
        <div class="resend-code">
            <a href="#" 
               [class.disabled]="counter > 0" 
               [style.pointer-events]="counter > 0 ? 'none' : 'auto'"
               (click)="resendCode($event)">Resend Code</a>
            <span *ngIf="counter > 0">({{ counter | number:'2.0-0' }}s)</span>
        </div>
    </div>
</div>

<div class="slots-wrapper" *ngIf="showCalanderSection">
    <div class="slots-title">
        <h2>Choose Booking Slots</h2>
    </div>
    <div class="d-flex flex-column gap-2">
        <div class="slot-item">
            <div class="date-selector">
                <div class="date-item" *ngFor="let date of dates | slice:0:6" (click)="selectDate(date)" [class.active]="selectedDate === date">
                    <div>{{ date | date:'EEE' }}</div>
                    <div><b>{{ date | date:'MMM d' }}</b></div>
                </div>
            </div>
            <div class="time-slots">
                <div class="slot-time" *ngFor="let slot of timeSlots" (click)="selectTimeSlot(slot)" [class.active]="selectedSlot === slot">
                    {{slot?.openTime}}-{{slot?.closeTime}}
                </div>
            </div>
        </div>
        <button class="continue-btn" type="button" (click)="continue()">Continue</button>
    </div>
</div>