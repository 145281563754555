<div class="hero-wrapper">
    <div class="container">
        <div class="hero-title">
            <h1>Explore Trusted <span>Home Service</span><br> Providers in Your City</h1>
            <div class="search-row">
                <div class="search-input-wrapper">
                    <i class="pi pi-search"></i>
                    <input type="text" (change)="search($event)" [placeholder]="currentPlaceholder" #searchInput/>
                </div>
                <button type="button">Search</button>
            </div>
        </div>
        <div class="category-col">
            @for (item of getAllIndustries; track item.industryId) {
                <div class="category-col-detail" style="background-image: url(../../../assets/img/cleaning.jpg)" (click)="viewAll(item)">
                    <h2>{{item?.value}}</h2>
                </div>
            }
        </div>
    </div>
</div>

<div class="contractor-col">
    <!--cleaning-category-section-->
    <div class="container" *ngIf="tanantList.length > 0">
        <div class="heading-row">
            <h2 class="section-heading">Cleaning Services in <span>{{selectedCity}}</span></h2>
            <button type="button" (click)="view('Cleaning')" class="d-none d-md-block">View All</button>
        </div>
        <div class="col-wrapper">
            @if(tanantList.length == 0){
                No Data Found
            }
            @for (item of tanantList; track item.companyName) {
                <div class="info-col" (click)="redirect(item)">
                    <div class="picture-col">
                        <span class="b-price">Starts from <b>${{item?.minPrice | number:'1.2-2'}}</b></span>
                        <img src="https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg" alt="Image"/>
                    </div>
                    <div class="title-row">
                        <div class="heading-row">
                            <h4>{{item?.companyName}}</h4>
                            <span class="c-rating"><i class="icon-rating"></i> {{item?.reviewCounter?.rate}}</span>
                        </div>
                        <p><i class=""></i>{{item?.address}}</p>
                    </div>
                </div>
            }
        </div>
    </div>

    <div class="container" *ngIf="filteredDataPest.length > 0">
        <div class="heading-row">
            <h2 class="section-heading">Pest Control Service in <span>{{selectedCity}}</span></h2>
            <button type="button" (click)="view('Pest Control')" class="d-none d-md-block">View All</button>
        </div>
        <div class="col-wrapper">
            @if(filteredDataPest.length == 0){
                No Data Found
            }
            @for (item of filteredDataPest; track item.companyName) {
                <div class="info-col" (click)="redirect(item)">
                    <div class="picture-col">
                        <span class="b-price">Starts from <b>${{item?.minPrice | number:'1.2-2'}}</b></span>
                        <img src="https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg" alt="Image"/>
                    </div>
                    <div class="title-row">
                        <div class="heading-row">
                            <h4>{{item?.companyName}}</h4>
                            <span class="c-rating"><i class="icon-rating"></i> {{item?.reviewCounter?.rate}}</span>
                        </div>
                        <p><i class=""></i>{{item?.address}}</p>
                    </div>
                </div>
            }
        </div>
    </div>

    <!--electrician-category-section-->
    <div class="container" *ngIf="Electrician.length > 0">
        <div class="heading-row">
            <h2 class="section-heading">Electricians in <span>{{selectedCity}}</span></h2>
            <button type="button" (click)="view('Electrician')" class="d-none d-md-block">View All</button>
        </div>
        <div class="col-wrapper">
            @if(Electrician.length == 0){
                No Data Found
            }
            @for (item of Electrician; track item.companyName) {
                <div class="info-col" (click)="redirect(item)">
                    <div class="picture-col">
                        <span class="b-price">Starts from <b>${{item?.minPrice | number:'1.2-2'}}</b></span>
                        <img src="https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg" alt="Image"/>
                    </div>
                    <div class="title-row">
                        <div class="heading-row">
                            <h4>{{item?.companyName}}</h4>
                            <span class="c-rating"><i class="icon-rating"></i>  {{item?.reviewCounter?.rate}}</span>
                        </div>
                        <p><i class=""></i>{{item?.address}}</p>
                    </div>
                </div>
            }
        </div>
    </div>

    <!--appliance-category-section-->
    <div class="container" *ngIf="ApplianceRepair.length > 0">
        <div class="heading-row">
            <h2 class="section-heading">Appliance Repair Services in <span>{{selectedCity}}</span></h2>
            <button type="button" (click)="view('ApplianceRepair')" class="d-none d-md-block">View All</button>
        </div>
        <div class="col-wrapper">
            @if(ApplianceRepair.length == 0){
                No Data Found
            }
            @for (item of ApplianceRepair; track item.companyName) {
                <div class="info-col" (click)="redirect(item)">
                    <div class="picture-col">
                        <span class="b-price">Starts from <b>${{item?.minPrice | number:'1.2-2'}}</b></span>
                        <img src="https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg" alt="Image"/>
                    </div>
                    <div class="title-row">
                        <div class="heading-row">
                            <h4>{{item?.companyName}}</h4>
                            <span class="c-rating"><i class="icon-rating"></i> {{item?.reviewCounter?.rate}}</span>
                        </div>
                        <p><i class=""></i>{{item?.address}}</p>
                    </div>
                </div>
            }
        </div>
    </div>

    <!--ac-repair-category-section-->
    <div class="container" *ngIf="HvacData.length > 0">
        <div class="heading-row">
            <h2 class="section-heading">AC Repair Services in <span>{{selectedCity}}</span></h2>
            <button type="button" (click)="view('HVAC')" class="d-none d-md-block">View All</button>
        </div>
        <div class="col-wrapper mobile-scroll grid-layout">
            @if(HvacData.length == 0){
                No Data Found
            }
            @for (item of HvacData; track item?.companyName) {
                <div class="info-col" (click)="redirect(item)">
                    <div class="picture-col">
                        <span class="b-price">Starts from <b>${{item?.minPrice | number:'1.2-2'}}</b></span>
                        <img src="https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg" alt="Image"/>
                    </div>
                    <div class="title-row">
                        <div class="heading-row">
                            <h4>{{item?.companyName}}</h4>
                            <span class="c-rating"><i class="icon-rating"></i>{{item?.reviewCounter?.rate}}</span>
                        </div>
                        <p><i class=""></i>{{item?.address}}</p>
                    </div>
                </div>
            }
        </div>
    </div>
</div>

<!--faqs-->
<div class="faqs-section">
    <div class="container">
        <div class="faq-wrapper">
            <h2 class="section-heading">Frequently <span>Asked</span> Questions</h2>
            <p-accordion [activeIndex]="0">
                <p-accordionTab header="How do I book a service?" iconPos="end">
                    <p>Simply search for the service you need, choose a provider from the list, and click <b>Book Now</b> to schedule your service. You can select your preferred date and time slot during booking.</p>
                </p-accordionTab>
                <p-accordionTab header="Are your service providers verified?" iconPos="end">
                    <p>Yes, all our service providers undergo thorough background checks and verification processes. We verify their professional credentials, experience, and conduct regular quality checks to ensure high service standards.</p>
                </p-accordionTab>
                <p-accordionTab header="What payment methods do you accept?" iconPos="end">
                    <p>We accept multiple payment methods including credit/debit cards, UPI, net banking, and cash on service completion. All online payments are processed through secure payment gateways.</p>
                </p-accordionTab>
                <p-accordionTab header="What if I'm not satisfied with the service?" iconPos="end">
                    <p>Customer satisfaction is our priority. If you're not satisfied with the service, please report it within 24 hours and we'll arrange for a re-service or provide a refund as per our service guarantee policy.</p>
                </p-accordionTab>
                <p-accordionTab header="Do you provide emergency services?" iconPos="end">
                    <p>Yes, we offer emergency services for urgent requirements like plumbing issues, electrical problems, or AC breakdowns. Our emergency response team is available 24/7 to handle such situations.</p>
                </p-accordionTab>
                <p-accordionTab header="Are the service charges fixed?" iconPos="end">
                    <p>Service charges vary based on the type of service, scope of work, and materials required. You'll receive a detailed cost estimate before confirming the booking. Any additional charges will be communicated and approved by you beforehand.</p>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>

    <div class="container">
        <h2 class="section-heading">Your <span>Home Services</span>, Just a Click Away</h2>
        <div class="category-tag">
            @for (item of allCategories; track $index) {
                <span (click)="navigate(item)">{{item?.name}}</span>
            }
     
        </div>
    </div>
</div>


<!-- <p-dialog 
header="Location"
[modal]="true"
[(visible)]="visible" 
position="left" 
[style]="{ width: '25rem' }">
<div class="d-flex">
    <input type="text" placeholder="Chandigarh" #addressInput  class="form-input" />
</div>
</p-dialog> -->