import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingSharedService {

  // Local variable which stores 
  public cartItems: any = [];
  //public products = new Subject();

  products = new BehaviorSubject([]); getProductData$ = this.products.asObservable();

  constructor(private http: HttpClient) { }

  
  getProducts(): Observable<any> {
    return this.getProductData$;
  }

  setProducts(products: any) {
    this.cartItems.push(...products);
    this.products.next(products);
  }

  addProductToCart(product: any) {
    console.log(product)
    this.cartItems.push(product);
    this.products.next(this.cartItems);
  }

  updateServiceInCart(service: any) {
    // Find the index of the service in the cart
    const existingServiceIndex = this.cartItems.findIndex(
      (item: { categoryServiceId: any }) => item.categoryServiceId === service.categoryServiceId
    );
  
    if (existingServiceIndex > -1) {
      // Service already exists, update it
      const existingService = this.cartItems[existingServiceIndex];
  
      // Update the service quantity explicitly
      existingService.qty = service.qty;
  
      // Merge addOns, updating their qty explicitly
      service.addOns?.forEach((newAddon: any) => {
        const existingAddon = existingService.addOns.find(
          (addon: any) => addon.addOnId === newAddon.addOnId
        );
        if (existingAddon) {
          // Update the existing addOn's qty based on incoming data
          existingAddon.qty = newAddon.qty;
        } else {
          // Add new addOn if it doesn't exist
          existingService.addOns.push({ ...newAddon });
        }
      });
    } else {
      // Service does not exist, add it with its current qty
      this.cartItems.push({ ...service });
    }
  
    // Emit updated cart
    this.products.next(this.cartItems);
  
    console.log('Updated cart:', this.cartItems);
  }
  
  
  


  addProductToCartfromdomain(product: any,extras: any) {
    this.cartItems.push({
      ...product,
      extras: extras // or handle extras separately if needed
    });
    this.products.next(this.cartItems);
  }

  removeProductFromCart(addOnId: number) {
    this.cartItems.map((item: { addOnId: number; }, index: any) => {
      if (item.addOnId === addOnId) {
        this.cartItems.splice(index, 1);
      }
    });
    this.products.next(this.cartItems);
  }
  
  removeProductFromCartaddons(addOnId: number) {
    this.cartItems.forEach((cartItem: any) => {
      cartItem.addOns = cartItem.addOns.filter((addon: { addOnId: number; }) => addon.addOnId !== addOnId);
    });
  
    this.products.next(this.cartItems);
  }

removeProductFromCartExtras(extraId: number) {
  this.cartItems.forEach((cartItem: any) => {
    cartItem.extras = cartItem.extras.filter((extra: { extraId: number; }) => extra.extraId !== extraId);
  });

  this.products.next(this.cartItems);
}
removeProductFromCartExtras1(extraId: number, categoryServiceId: number) {
  // Iterate over cart items and check both extraId and categoryServiceId
  this.cartItems.forEach((cartItem: any) => {
    // Ensure you are targeting the correct service by categoryServiceId
    if (cartItem.categoryServiceId === categoryServiceId) {
      // Filter the extras to remove the one matching extraId
      cartItem.extras = cartItem.extras.filter((extra: { extraId: number }) => extra.extraId !== extraId);
    }
  });

  // Update the cartItems after filtering
  this.products.next(this.cartItems);
}



removeAdminProduct(categoryServiceId: number) {  
  this.cartItems.map((item: { categoryServiceId: number; }, index: any) => {
    if (item.categoryServiceId === categoryServiceId) {
      this.cartItems.splice(index, 1);
    }
  });
  this.products.next(this.cartItems);
}

removeAddOn(addOnId: number) {  
  // Use forEach to iterate and remove the specific add-on by its id
  this.cartItems.forEach((item:any, index:any) => {
    if (item.addOnId === addOnId) {
      this.cartItems.splice(index, 1); // Remove the specific add-on
    }
  });
  
  // Emit the updated cart items list
  this.products.next(this.cartItems);
}



  emptyCart() {
    this.cartItems.length = 0;
    this.products.next(this.cartItems);
  }

  triggerObersevable() {
    this.products.next(this.cartItems);
  }

  // Calculate total price on item added to the cart
  getTotalPrice() {
    let total = 0;    
    this.cartItems.map((item: { price: number; }) => {
      total += item.price;
    });
    return total;
  }

  getAddedAddons() {
    let result = this.cartItems.map((elem: { serviceId: any; addOnId: any; }) => (
      {
        serviceId: elem.serviceId,
        addOnId: elem.addOnId
      }
    ));
    return result;
  }

  getTax(type: any) {
    let token:any = localStorage.getItem('helpify');
    
    token = JSON.parse(token);
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token?.data?.token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/api/v1/Master/GetTax?taxName=` + type,{headers: headers })
      .pipe(map(res => {
        return res;
      }));
  }
  

  
}
