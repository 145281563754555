import { Component, inject, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { InputOtpModule } from 'primeng/inputotp';
import moment from 'moment';
import { VacentService } from 'src/app/core/services/vacent.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, InputGroupModule, InputGroupAddonModule, InputTextModule, CommonModule,InputOtpModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnDestroy {
  private modalService = inject(NgbModal);
  counter: number = 0;
  private countdownTimer: any;
  showVerification = false;
  showCalanderSection = false;
  dates: Date[] = [];
  todayDate: Date = new Date();
  selectedDate: Date | null = null;
  timeSlots: any[] = [];
  selectedSlot: string | null = null;
  value : any
  businessHours: any[] = [];
  bookingTime: any;
  bookingTimeText: any;
  isEnableNextBtn: boolean = false;
  bookingDate: Date | null | undefined;
  constructor(
    private router: Router,
    private tenent:VacentService
  ) {}

  ngOnInit() {
    // Populate next 7 days as an example
    for(let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      this.dates.push(date);
    }
    if (this.dates.length > 0) {
      this.selectDate(this.dates[0]);
    }
    if (this.timeSlots.length > 0) {
      this.selectTimeSlot(this.timeSlots[0]);
    }

    const date = moment(this.todayDate);
    const dow = date.day();
    this.bindBusinessHours(dow);
  }

  resendCode(event: Event) {
    event.preventDefault();
    if (this.counter > 0) return;
    
    this.counter = 20; // Set initial countdown time (20 seconds)
    this.countdownTimer = setInterval(() => {
      this.counter--;
      if (this.counter <= 0) {
        clearInterval(this.countdownTimer);
      }
    }, 1000);
    
    // Add your resend code logic here
  }

  showVerificationSection() {
    this.showVerification = true;
  }

  goBack() {
    this.showVerification = false;
  }

  closeDialog(): void {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
    }
  }

  selectDate(date: Date): void {
    this.selectedDate = date;
    const originalDate = new Date(date);

    // Convert to ISO 8601 format
    // this.bookingFormDate = originalDate.toISOString();
    const date1 = moment(date);
    const dow = date1.day();
    this.bindBusinessHours(dow);
  }

  selectTimeSlot(slot: string): void {
    this.selectedSlot = slot;
  }

  onOtpComplete() {
    this.showCalanderSection = this.value.length === 4;
    if(this.showCalanderSection){
      this.showVerification = false
    }
  }

  bindBusinessHours(dayId: number) {
    this.tenent.getBusinessHourByWeekDay(dayId).subscribe((res) => {
      // this.loading = false;
      this.businessHours = res.data;
      this.timeSlots = this.businessHours
      if (this.businessHours.length > 0) {
        // this.bookingForm.patchValue({
        //   bookingTime: this.businessHours[0].businessHourId,
        // });
        this.bookingTime = this.businessHours[0].businessHourId;
        this.bookingTimeText =
          this.businessHours[0].openTime +
          '-' +
          this.businessHours[0].closeTime;
        this.isEnableNextBtn = true;
        // this.cdr.detectChanges();
      } else {
        this.bookingTime = 0;
      }
    });
  }

  continue(){
    var _data = {
      'mobileNo': 7986046893,
      'bookingDate': this.selectedDate,
      'businessHours': this.businessHours,
      'bookingTimeText': this.bookingTimeText,
    }
    this.router.navigate(['checkout'], { state: _data })
    this.modalService.dismissAll()
  }

  close(){
    this.modalService.dismissAll()

  }
}
