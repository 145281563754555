import { Component, OnInit } from '@angular/core'; // Importing the Component decorator from Angular Core
import { BreadcrumbModule } from 'primeng/breadcrumb'; // Importing BreadcrumbModule from PrimeNG for breadcrumb navigation
import { MenuItem } from 'primeng/api'; // Importing MenuItem from PrimeNG API for menu items
import { AccordionModule } from 'primeng/accordion'; // Importing AccordionModule from PrimeNG for accordion functionality
import { SliderModule } from 'primeng/slider'; // Importing SliderModule from PrimeNG for slider functionality
import { FormsModule } from '@angular/forms'; // Importing FormsModule from Angular for form handling
import { CommonModule } from '@angular/common'; // Importing CommonModule from Angular for common directives
import { PaginatorModule } from 'primeng/paginator'; // Importing PaginatorModule from PrimeNG for pagination
import { VacentService } from 'src/app/core/services/vacent.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search', // Component selector
  standalone: true, // Component is standalone
  imports: [
    CommonModule, // Importing CommonModule for common directives
    BreadcrumbModule, // Importing BreadcrumbModule for breadcrumb navigation
    AccordionModule, // Importing AccordionModule for accordion functionality
    SliderModule, // Importing SliderModule for slider functionality
    FormsModule, // Importing FormsModule for form handling
    PaginatorModule // Importing PaginatorModule for pagination
  ],
  templateUrl: './search.component.html', // Component template URL
  styleUrl: './search.component.scss' // Component style URL
})
export class SearchComponent implements OnInit {
  items: MenuItem[] = []; // Array to hold menu items
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/' }; // Home menu item
  activeIndices = [0, 1, 2];  // Opens all three tabs by default
  rangeValues: number[] = [100, 5000]; // Default values for the price range
  rangeValue: number = 0; // Current value for the price range
  first: number = 0;
  rows: number = 2; // Number of items per page
  totalRecords: number = 0;
  currentPage: number = 0;
  allTimedays:any[]=[]
  cleaners = Array(5).fill({
    rating: '4.4',
    name: 'Elite Winds Home Services',
    address: 'Sector 25, Chandigarh',
    services: [
      'Home Deep Cleaning',
      'Bathroom Deep Cleaning',
      'Kitchen Deep Cleaning',
      'Sofa Deep Cleaning',
      'Carpet Deep Cleaning'
    ],
    startPrice: 345
  }); // Array of cleaners with their details
  sortOptions = [
    { label: 'Price: Low to High', value: 'price_asc' },
    { label: 'Price: High to Low', value: 'price_desc' },
    { label: 'Most Popular', value: 'popularity' },
  ]; // Options for sorting
  selectedSort: any; // Selected sort option
  ratingOptions = [
    { label: '5★', value: 5 },
    { label: '4★', value: 4 },
    { label: '3★', value: 3 },
    { label: '2★', value: 2 },
    { label: '1★', value: 1 }

  ]; // Options for rating filter
  selectedRating: any = null; // Selected rating filter
  storeIndustires:any[]=[];
  tanantList:any[]=[];
  storedData: any;
  indusType: string | null='';
  categoryString: any;
  timeofDays:any = '';
  isMobileView: boolean = false;
  selectedCity:any = 'Chandigarh'
  filteredList: any[] = [];
  minPriceFrom: any;
  sliderMinValue: number = 0; // Minimum value for slider
sliderMaxValue: number = 10000; // Maximum value for slider
  constructor(private tenent:VacentService,private route:Router) {
    this.items = [
      { label: 'Service' }
    ]; // Initializing the items array with a single item
  }
  ngOnInit(): void {
    this.applyFilters();
    this.selectedCity = localStorage.getItem('location')

    this.getAllTimeDays()
    const type = localStorage.getItem('industriesType');
    this.indusType = type
    const stored = localStorage.getItem('industries');
    if(stored){
      let storedData = JSON.parse(stored)
      this.storedData = JSON.parse(stored)
      console.log(this.storedData.value)
      this.storeIndustires = storedData.categories?.split(',');
      
      this.getAllTanent()
    // this.storeIndustires.push(servicesArray)
   }
   this.tenent.isPopupOpenSubject.subscribe({next:(res)=>{
    if(res){
      this.selectedCity = localStorage.getItem('location')
      this.getAllTanent()
    }

  }})
  
  }
  onPageChange(event: any) {
    this.first = event.first; // Updating the first record index
    this.rows = event.rows; // Updating the number of rows to display per page
    // Add any additional pagination logic here
    this.currentPage = event.page;
    this.getAllTanent(); // Fetch data for the new page
  }

  getIndustries(): any[] {
    const stored = localStorage.getItem('industries');
    return stored ? JSON.parse(stored).value : [];
  }

  getAllTimeDays(){
    this.tenent.getAllTimeDay().subscribe({next:(res)=>{
      this.allTimedays = res?.data
    }})
  }

  getAllTanent(){
    let payload = {
    "id": 0,
    "pageNumber": this.currentPage,
    "pageSize": this.rows,
    "searchStr": "",
    "customerUserId": "",
    "tenantId": 0,
    "isPopular": false,
    "city": "",
    "industry":this.indusType,
    "timeOfDay":this.timeofDays,
    "minPrice": this.rangeValue,
    "maxPrice": 10000,
    "isHelpify": "Y"
    }

    this.tenent.getAllTenent(payload).subscribe({next:(res)=>{
      this.tanantList = res?.data.filter(
        (item: { city: string }) => item.city === this.selectedCity
      );
      this.filteredList = this.tanantList
      const minPriceObject =  this.filteredList.reduce((min, current) => {
        return current.minPrice < min.minPrice ? current : min;
      },  this.filteredList[0]);
      this.minPriceFrom = minPriceObject
    }})
    
  }
  selectedTimeOfDays: string[] = []; // Array to store selected checkboxes

  timeOfDays(event: Event, value: string) {
    const checkbox = event.target as HTMLInputElement;
  
    if (checkbox.checked) {
      // Add value to the array if checked
      this.selectedTimeOfDays.push(value);
    } else {
      // Remove value if unchecked
      this.selectedTimeOfDays = this.selectedTimeOfDays.filter(
        (item) => item !== value
      );
    }
  
    console.log(this.selectedTimeOfDays); // Log selected values
   this.timeofDays= this.selectedTimeOfDays.join(',');
    let payload = {
      id: 0,
      pageNumber: 0,
      pageSize: 0,
      searchStr: "",
      customerUserId: "",
      tenantId: 0,
      isPopular: false,
      city: "",
      industry: this.indusType,
      timeOfDay:  this.timeofDays, // Use array of selected values
      minPrice: this.rangeValue,
      maxPrice: 10000,
      isHelpify: "Y",
    };
  
    this.tenent.getAllTenent(payload).subscribe({
      next: (res) => {
        this.tanantList = res?.data.filter(
          (item: { city: string }) => item.city === this.selectedCity
        );
        this.filteredList = this.tanantList;
  
        const minPriceObject = this.filteredList.reduce((min, current) => {
          return current.minPrice < min.minPrice ? current : min;
        }, this.filteredList[0]);
  
        this.minPriceFrom = minPriceObject;
      },
    });
  }
  

  onSliderChange(event: any) {
    this.rangeValue = event?.value
    // Update the price in your payload and fetch new data
    this.getAllTanent();
  }

  category(event: any) {
    let categories = new Set(this.categoryString ? this.categoryString.split(', ') : []);
    
    if (categories.has(event)) {
        // Remove if exists
        categories.delete(event);
    } else {
        // Add if doesn't exist
        categories.add(event);
    }
    
    // Convert Set back to string
    this.categoryString = Array.from(categories).join(', ');
    
    console.log(this.categoryString);

    let payload = {
      "id": 0,
      "pageNumber": 0,
      "pageSize": 0,
      "searchStr": "",
      "customerUserId": "",
      "tenantId": 0,
      "isPopular": false,
      "city": "",
      "industry":this.indusType,
      "timeOfDay": this.timeofDays,
      "minPrice": this.rangeValue,
      "maxPrice": 10000,
      "serviceOffer":this.categoryString,
      "isHelpify": "Y"
      }
  
      this.tenent.getAllTenent(payload).subscribe({next:(res)=>{
        this.tanantList = res?.data.filter(
          (item: { city: string }) => item.city === this.selectedCity
        );
      this.filteredList = this.tanantList
      const minPriceObject =  this.filteredList.reduce((min, current) => {
        return current.minPrice < min.minPrice ? current : min;
      },  this.filteredList[0]);
      this.minPriceFrom = minPriceObject

      }})
}

  bookingStart(cleaner:any){
    console.log(cleaner)
    localStorage.setItem('selectedCleaner', JSON.stringify(cleaner));
     // Construct the dynamic route parts
     const city = cleaner.city?.toLowerCase().replace(/\s+/g, '-'); // Replace spaces with hyphens
     const category = cleaner.industry?.toLowerCase().replace(/\s+/g, '-'); // Replace spaces with hyphens
     const contractorName = cleaner.companyName?.toLowerCase().replace(/\s+/g, '-'); // Replace spaces with hyphens
   
     // Navigate to the dynamic route
     this.route.navigate([`/${city}/${category}/${contractorName}`]);
     this.route.events.subscribe(() => {
      window.scrollTo(0, 0); // Scrolls to the top of the page
    });
  }

  toggleFilters() {
    this.isMobileView = !this.isMobileView;
    // Prevent body scroll when filter is open
    if (this.isMobileView) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
    }
  }

  applyFilters(): void {
    this.filteredList = [...this.tanantList]; // Copy original list
  
    // Filter by Rating
    if (this.selectedRating) {
      this.filteredList = this.filteredList.filter(cleaner => 
        cleaner.reviewCounter?.rate && cleaner.reviewCounter.rate == this.selectedRating.value
      );
    }
  
    // Apply Sorting
    if (this.selectedSort) {
      switch (this.selectedSort.value) {
        case 'price_asc':
          this.filteredList.sort((a, b) => a.minPrice - b.minPrice);
          break;
        case 'price_desc':
          this.filteredList.sort((a, b) => b.minPrice - a.minPrice);
          break;
        case 'popularity': // Sort by rating
          this.filteredList.sort((a, b) => 
            (b.reviewCounter?.rate || 0) - (a.reviewCounter?.rate || 0) // Handle missing ratings
          );
          break;
      }
    }
  
    // Reset paginator
    this.first = 0;
  }
  showAll: boolean = false; // State to toggle between "More" and "Less"

  // Getter to dynamically control the services displayed
  // get visibleServices() {
  //   const servicesArray = this.cleaner?.servicesOffer?.split(',') || [];
  //   return this.showAll ? servicesArray : servicesArray.slice(0, 4);
  // }

  toggleServices() {
    this.showAll = !this.showAll;
  }

}
