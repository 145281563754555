import { HttpInterceptorFn } from '@angular/common/http';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  let token:any = localStorage.getItem('helpify');
  token = JSON.parse(token);
  const modifiedReq = req.clone({
    // Example: Add headers
    setHeaders: {
      'Authorization': `Bearer ${token?.data?.token}`
    }
  });

  // Pass the modified request to the next handler
  return next(modifiedReq);
};
