<header class="header">
    <div class="container">
        <div class="header-wrapper">
            <div class="logo-wrapper">
                <!-- <img src="assets/images/logo.png" alt="logo" class="logo"> -->
                <h2 class="logo d-none d-md-block">Logo</h2>

                <div class="form-input" (click)="openLocation()">
                    <span>{{selectedCity}}</span>
                    <i class="pi pi-angle-down" style="font-size: 1rem"></i>
                </div>
            </div>
            <nav class="desktop-nav">
                <ul>
                    <ng-container *ngTemplateOutlet="navItems"></ng-container>
                </ul>
            </nav>
            <button class="mobile-menu-btn" (click)="showMobileMenu()">
                <i class="icon-menu"></i>
            </button>
        </div>
    </div>
</header>

<p-dialog 
    [(visible)]="mobileMenuVisible" 
    [position]="'bottom'" 
    [modal]="true"
    [style]="{ width: '100vw', margin: 0 }"
    [draggable]="false"
    [resizable]="false"
    [showHeader]="false">
    <div class="mobile-menu">
        <nav>
            <ul class="mobile-nav-list">
                <ng-container *ngTemplateOutlet="navItems"></ng-container>
            </ul>
        </nav>
        <div class="text-center mt-3">
            <button class="close-btn" (click)="mobileMenuVisible = false">
                <i class="pi pi-times"></i> Close
            </button>
        </div>
    </div>
</p-dialog>

<!-- Common Navigation Template -->
<ng-template #navItems>
    <li class="border-0"><a href="#"><i class="icon-offer"></i>Offer's</a></li>
    <li><a href="#"><i class="icon-faqs"></i>FAQs</a></li>
    <li><a href="#"><i class="icon-help"></i>Help</a></li>
    <li><a (click)="openLogin()" class="login-btn">Login</a></li>
</ng-template>