import { Routes } from '@angular/router';
import { SearchComponent } from './components/search/search.component';
import { LoginComponent } from './components/login/login.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { HelpPageComponent } from './components/help-page/help-page.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { OffersComponent } from './components/offers/offers.component';
import { LayoutComponent } from './components/layout/layout.component';
import { SuccessPageComponent } from './components/success-page/success-page.component';
import { PaymentFailedComponent } from './components/payment-failed/payment-failed.component';
import { LocationComponent } from './components/location/location.component';

export const routes: Routes = [
    {
        path:':city/:category',
        component:SearchComponent
    },
    // {
    //     path:'login',
    //     component:LoginComponent,
    // },
    {
        path: ':city/:category/:contractorName',
        component: HelpPageComponent,
    },
    {
        path:'checkout',
        component:CheckoutComponent,
    },
    {
        path:'faqs',
        component:FaqsComponent,
    },
    {
        path:'offers',
        component:OffersComponent,
    }
    ,
    {
        path:'',
        component:LayoutComponent,
    },
    {
        path:'payment-success',
        component:SuccessPageComponent
    },
    {
        path:'payment-failure',
        component:PaymentFailedComponent
    },
    {
        path:'location',
        component:LocationComponent
    }
];
