<div class="hp-c-header">
    <div class="container">
        <div class="hp-c-header-wrapper">
            <div>
                <p-breadcrumb class="max-w-full dark-breadcrumb-style" [model]="items" [home]="home"/>
                <h1 class="hp-c-title mb-15">{{cleaner.companyName}}</h1>

                <div class="hp-c-quick-contact">
                    <div class="hp-c-address-time">
                        <span class="hp-c-address"> 
                            <i class="icon-location"></i> {{cleaner?.address}}
                        </span>
                        <span class="hp-c-time">
                            <div [ngClass]="{'open-time': isOpen, 'close-time': !isOpen}">
                              {{ isOpen ? 'Open Now' : 'Closed Now' }}
                            </div>
                            {{ openTime }} - {{ closeTime }}
                          </span>
                          
                        <span class="hp-c-mobile">
                            <i class="icon-call"></i> {{cleaner?.phoneNumber}}
                        </span>
                    </div>
                    <div class="hp-c-rating">
                        @for (star of [1, 2, 3, 4, 5]; track star) {
                            <i class="pi pi-star-fill" [class.filled]="star <= cleaner?.reviewCounter?.rate" [class.pi-star-half]="star - 0.5 === cleaner?.reviewCounter?.rate"></i>
                        }
                        <span class="rating-text">{{cleaner?.reviewCounter?.rate}} ({{cleaner?.reviewCounter?.totalReview}} Reviews)</span>
                    </div>
                </div>
            </div>
            <div class="hp-c-gallery">
                <div class="gallery-col col-1">
            
                    @if(cleaner.mediaGallery.videos.length == 0){
                        <img src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg" alt="Cleaning supplies"/>
                      }
                    <div class="video-item" *ngFor="let video of cleaner.mediaGallery.videos">
                        @if(cleaner.mediaGallery.videos.length > 0){
                            <div *ngIf="cleaner.mediaGallery.videos.length > 0">
                              <video controls>
                                <source [src]="video.filePath ? apiUrl + '/' + video.filePath : 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'" type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>

                        }@else{
                            <img src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg" alt="Cleaning supplies"/>

                        }
                      </div>
                </div>
                <div class="gallery-col col-2">
                    @if(cleaner.mediaGallery.images.length == 0){
                        <img src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg" alt="Cleaning supplies"/>
                      }
                    @for (item of cleaner.mediaGallery.images; track $index) {
                        <div class="image-item">
                            <img [src]="item.filePath?apiUrl+'/'+item.filePath:'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'" alt="Cleaning supplies"/>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>

    <!-- Mobile/Tablet Gallery Slider -->
    <div class="mobile-gallery">
        <p-carousel 
            [value]="galleryImages" 
            [numVisible]="1" 
            [numScroll]="1" 
            [circular]="true"
            [showNavigators]="false"
            [autoplayInterval]="3000">
            <ng-template let-image pTemplate="item">
                <div class="carousel-item">
                    <img [src]="image.url" [alt]="image.alt"/>
                </div>
            </ng-template>
        </p-carousel>
    </div>
</div>

<div class="container">
    <div class="result-list">
        <div>
            <h2 class="section-heading">Services</h2>
            <p-tabView styleClass="capsule-tabs">
                @for (category of itemsData; track category.categoryId) {
                  <p-tabPanel [header]="category.name || 'Home Cleaning'">
                    <div class="service-list" *ngIf="category.categoryServices.length > 0">
                      @for (service of category.categoryServices; track service.categoryServiceId) {
                        <div class="service-item">
                          <div class="service-image">
                            <!-- <img [src]="(service)" alt="Service Image"> -->
                          </div>
                          <div class="price">${{ service.price |number:'1.2-2'}}</div>
                          <div class="item-name">
                            <h3>{{ service.serviceName || 'Standard Home Cleaning' }}</h3>
                            <p>{{ service.description || 'General cleaning of all rooms, dusting, vacuuming, mopping, and surface cleaning.' }}</p>
                          </div>
                          <button type="button" (click)="addData(service)">
                            <i class="pi pi-plus"></i>
                           Add
                          </button>
                        </div>
                      }
                    </div>
                    <p *ngIf="category.categoryServices.length === 0" class="no-services">
                      No services available for this category.
                    </p>
                  </p-tabPanel>
                }
              </p-tabView>
        </div>

        <!-- Desktop Booking Form -->
        <div class="booking-form d-none d-lg-block">
            <h2 class="d-flex align-items-center gap-2"><i class="icon-cart"></i>Booking Cart</h2>
            <div class="form-wrapper">
                <div class="form-items-list">
                    @if(selectedServices.length == 0){
                        No data found
                    }
                    @for (service of selectedServices; track service) {
                        <div class="form-item" >
                            <div class="form-value">
                                <div>{{service.serviceName}}</div>
                                <!-- <div class="price-text">${{service?.price|number:'1.2-2'}}</div> -->
                                <button class="edit-button" type="button" (click)="editService(service.categoryServiceId,service)"><i class="pi pi-pen-to-square
                                    " style="font-size: 1rem"></i></button>

                                <button class="edit-button"  type="button" (click)="removeAddons(service.categoryServiceId,service)"><i class="pi pi-trash" style="font-size: 1rem"></i></button>
                            </div>
                        </div>
                        <div class="d-flex flex-column gap-1">
                            <div class="text-muted d-flex align-items-start justify-content-between" *ngFor='let option of service.addOns ; index as i'>
                                <div class="font13 text-capitalize">{{option?.addOnsName}} x {{option?.qty}}</div>
                                <div class="d-flex gap-2 align-items-center">
                                    <div class="font13 font400">${{option.price|number:'1.2-2'}}</div>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let extraItem of service?.extras">
                            <div class="extras-span font-muted" *ngIf="extraItem.qty > 0">
                                <div class="font14 font400 text-capitalize">
                                    {{extraItem.name}}
                                </div>
                                <div class="d-flex align-items-center gap-3">
                                    <div class="font13 font400">
                                        ${{extraItem.price | number: '1.2-2'}} x {{extraItem.qty}}
                                    </div>
                                    <a href="javascript:void(0);" matTooltip="Remove" (click)="removeExtra(service.categoryServiceId, extraItem?.extraId,service)" 
                                        class="text-danger font13">
                                        <i class="pi pi-trash" style="font-size: 1rem"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <div class="cart-sub-total">
                        <!-- <div class="form-value">
                            <div>Discount</div>
                            <div class="price-text">$20.00</div>
                        </div> -->
                        <div class="form-value">
                            <div>Sub Total</div>
                            <div class="price-text">${{subTotal|number:'1.2-2'}}</div>
                        </div>
                        <div class="form-value">
                            <div>Tax({{taxPer}}%)</div>
                            <div class="price-text">${{taxAmount|number:'1.2-2'}}</div>
                        </div>
                    </div>
                    <div class="cart-total">
                        <div class="form-value">
                            <div>Total</div>
                            <div>${{totalCartAmt|number:'1.2-2'}}</div>
                        </div>
                    </div>
                </div>
                <button class="book-now-button" (click)="bookNow()">Book Now</button>
            </div>
        </div>

        <!-- Mobile/Tablet Bottom Sheet -->
        <div class="mobile-booking-sheet show-on-mobile">

            <!-- Floating Cart Button -->
            <button class="floating-cart-button" (click)="openBottomSheet()">Book Now</button>

            <!-- Bottom Sheet Content -->
            <p-dialog 
                [(visible)]="isBottomSheetVisible" 
                [modal]="true"
                [position]="'bottom'"
                [style]="{width: '100vw', margin: 0}"
                styleClass="mobile-bottom-sheet">
                <ng-template pTemplate="header">
                    <div class="sheet-heading"><i class="icon-cart"></i>Booking Cart</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="booking-form">
                        <div class="form-wrapper">
                            <div class="form-items-list">
                                @if(selectedServices.length == 0){
                                    No data found
                                }
                                @for (service of selectedServices; track service) {
                                    <div class="form-item">
                                        <div class="form-value">
                                            <div>{{service.serviceName}}</div>
                                            <!-- <div class="price-text">${{service?.price|number:'1.2-2'}}</div> -->
                                            <button class="edit-button" (click)="removeAddons(service.categoryServiceId,service)"><i class="pi pi-trash" style="font-size: 1rem"></i></button>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column gap-1">
                                        <div class="text-muted d-flex align-items-start justify-content-between" *ngFor='let option of service.addOns ; index as i'>
                                            <div class="font13 text-capitalize">{{option.addOnsName}}</div>
                                            <div class="d-flex gap-2 align-items-center">
                                                <div class="font13 font400">${{option.price|number:'1.2-2'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngFor="let extraItem of service?.extras">
                                        <div class="extras-span font-muted" *ngIf="extraItem.qty > 0">
                                            <div class="font14 font400 text-capitalize">
                                                {{extraItem.name}}
                                            </div>
                                            <div class="d-flex align-items-center gap-3">
                                                <div class="font13 font400">
                                                    ${{extraItem.price | number: '1.2-2'}} x {{extraItem.qty}}
                                                </div>
                                                <a href="javascript:void(0);" matTooltip="Remove" (click)="removeExtra(service.categoryServiceId, extraItem?.extraId,service)" 
                                                    class="text-danger font13">
                                                    <i class="pi pi-trash" style="font-size: 1rem"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div>
                                <div class="cart-sub-total">
                                    <!-- <div class="form-value">
                                        <div>Discount</div>
                                        <div class="price-text">$20.00</div>
                                    </div> -->
                                    <div class="form-value">
                                        <div>Sub Total</div>
                                        <div class="price-text">${{subTotal|number:'1.2-2'}}</div>
                                    </div>
                                    <div class="form-value">
                                        <div>Tax({{taxPer}}%)</div>
                                        <div class="price-text">${{taxAmount|number:'1.2-2'}}</div>
                                    </div>
                                </div>
                                <div class="cart-total">
                                    <div class="form-value">
                                        <div>Total</div>
                                        <div>${{totalCartAmt|number:'1.2-2'}}</div>
                                    </div>
                                </div>
                            </div>
                            <button class="book-now-button" (click)="bookNow()">Book Now</button>
                        </div>
                    </div>
                </ng-template>
            </p-dialog>
        </div>
    </div>
</div>

<!--about-->
<div class="container">
    <div class="about-section">
        <h2 class="section-heading mb-3">About <span>{{cleaner?.industry}}</span> Services</h2>
        <div class="location-map">
            <iframe 
                [src]="sanitizedMapUrl" 
                style="border:0; border-radius: 16px; width: 100%; height: 100%;" 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        </div>
        <div class="about-text">
            <p>Fames vivamus, pede beatae doloribus totam accusamus fermentum praesent ad esse non nisi. Eget varius eligendi vestibulum aut natus dignissim doloremque purus penatibus nemo? Volutpat. Earum curabitur. Dui eleifend repellat, occaecat leo! Eveniet ipsa alias maiores, maxime perferendis, iusto odio vitae risus! Debitis volutpat torquent. Aute pellentesque dolorum nibh soluta.</p>
        </div>
        <div class="keywords-list">
            @for (keyword of keywords; track keyword) {
                <div class="keyword-item tag">
                    <span>{{ keyword }}</span>
                </div>
            }
        </div>
    </div>
</div>

<!--reviews-->
<div class="container">
    <div class="reviews-section">
        <h2 class="section-heading">What Our <span>Customers</span> Are Saying</h2>
        <div class="reviews-wrapper">
            <div class="reviews-stats">
                <div class="review-stats-item">
                    <div class="review-stats-value">{{cleaner?.reviewCounter?.rate}}</div>
                    <div class="review-stats-divider">
                        @for (star of [1, 2, 3, 4, 5]; track star) {
                            <i class="pi pi-star-fill" [class.filled]="star <= cleaner?.reviewCounter?.rate" [class.pi-star-half]="star - 0.5 === cleaner?.reviewCounter?.rate"></i>
                        }
                        <!-- <i class="pi pi-star-fill"></i>
                        <i class="pi pi-star-fill"></i>
                        <i class="pi pi-star-fill"></i>
                        <i class="pi pi-star-fill"></i>
                        <i class="pi pi-star"></i> -->
                    </div>
                    <div class="review-stats-text">{{cleaner?.reviewCounter?.totalReview}} Review</div>
                </div>
                <div class="review-stats-range">
                    @for (stat of cleaner?.reviewCounter?.stats; track stat.rating) {
                        <div class="range-item">
                            <span>{{ stat?.rating }} <i class="pi pi-star-fill"></i></span>
                            <div class="range-bar">
                                <div class="range-fill" [style.width.%]="stat?.percentage"></div>
                            </div>
                            <span>{{ stat?.totalCount }}</span>
                        </div>
                    }
                </div>
            </div>
            <div class="reviews-list">
                @for (review of reviewList; track review) {
                    <div class="review-item">
                        <div class="review-user-image">
                            <!-- <img src="https://randomuser.me/api/portraits/men/{{review}}.jpg" alt="User profile image"/> -->
                        </div>
                        <div class="review-user-name">
                            <div class="review-user-rating">
                                <div class="d-flex align-items-center gap-1">
                                    @for (star of [1, 2, 3, 4, 5]; track star) {
                                        <i class="pi pi-star-fill" [class.filled]="star <= review?.rate" [class.pi-star-half]="star - 0.5 === review?.rate"></i>
                                    }
                                </div>
                                <span class="review-date">{{review?.createdDate | date}}</span>
                            </div>
                            <div>
                                <div class="review-user-name-text"> {{review?.name}}</div>
                                <div class="review-message">
                                    <p>{{review?.feedback}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>

<!--faqs-->
<div class="hc-faq-section">
    <div class="container">
        <div class="hc-faq-wrapper">
            <h2 class="section-heading">Frequently <span>Asked</span> Questions</h2>
            <p-accordion [activeIndex]="0">
                @for (item of faqsList; track $index) {
                    <p-accordionTab [header]="item?.question" iconPos="end">
                        <p>{{item?.answer}}</p>
                    </p-accordionTab>
                }
            </p-accordion>
        </div>
    </div>
</div>

<div class="card flex justify-content-center">
    <p-dialog 
        header="Header" 
        [(visible)]="visible" 
        [modal]="true" 
        [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" 
        [style]="{ width: '50vw' }" 
        [draggable]="false" 
        [resizable]="false"
        [closable]="true"
        closeIcon="pi pi-times"
        (onHide)="visible = false">
        <p>
            <app-add-service [service]="selectedService"></app-add-service>
        </p>
    </p-dialog>
</div>