<div class="search-header">
    <div  class="container">
        <p-breadcrumb class="max-w-full light-breadcrumb-style" [model]="items" [home]="home" />
        <h1 class="page-title">{{tanantList.length}} {{indusType}} in <span>{{selectedCity}}</span></h1>
    </div>
</div>

<!--offers-section-->
<div class="container">
    <div class="offer-wrapper">
        <div>
            <img src="../../../assets/img/offer-1.png" alt="image"/>
        </div>
        <div>
            <img src="../../../assets/img/offer-2.png" alt="image"/>
        </div>
        <div>
            <img src="../../../assets/img/offer-3.png" alt="image"/>
        </div>
    </div>
</div>

<!--search-section-->
<div class="container">
    <div class="search-result-wrapper">
        <!-- Filter toggle button for mobile/tablet -->
        <button class="filter-toggle-btn" (click)="toggleFilters()">
            <i class="pi pi-filter"></i> Filters
        </button>

        <!--search-filter-->
        <div class="filter-sidebar" [ngClass]="{'show': isMobileView}">
            <!-- Moved filter header to top of sidebar -->
            <div class="filter-header">
                <h3>Filters</h3>
                <button class="filter-close" (click)="toggleFilters()">
                    <i class="pi pi-times"></i>
                </button>
            </div>

            <p-accordion [multiple]="true" [activeIndex]="[0,1,2]">
                <p-accordionTab header="Time of day" iconPos="end">
                    <div class="button-group">
                        @for (item of allTimedays; track $index) {
                            <label class="custom-radio-btn">
                                <input type="checkbox" name="timeOfDay" value="{{item?.value}}" (change)="timeOfDays($event,item?.value)">
                                <span class="btn-label">{{item?.text}}</span>
                            </label>
                        }
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Price" iconPos="end">
                    <div class="price-range">
                        <p-slider [min]="0" [max]="10000" [(ngModel)]="rangeValue" [step]="100" class="w-full" (onChange)="onSliderChange($event)"></p-slider>
                        <div class="price-labels">
                            <span>₹{{rangeValue}}</span>
                            <span>₹10000</span>
                        </div>
                    </div>
                </p-accordionTab>

                <p-accordionTab header="{{indusType}} Category" iconPos="end">
                    <div class="category-tags">
                        @for (item of storeIndustires; track $index) {
                            <label class="custom-radio-btn">
                                <input type="checkbox" name="cleaningCategory" value="home" (change)="category(item)">
                                <span class="btn-label">{{item}}</span>
                            </label>
                        }
                    </div>
                </p-accordionTab>
            </p-accordion>

            <div class="filter-footer">
                <button class="filter-apply" (click)="applyFilters(); toggleFilters()">
                    Apply Filters
                </button>
            </div>
        </div>

        <!--search-result-->
        <div class="result-list">
            <div class="result-filter">
                <p-dropdown 
                    [options]="sortOptions" 
                    [(ngModel)]="selectedSort" 
                    optionLabel="label" 
                    placeholder="Sort by" 
                    (onChange)="applyFilters()" 
                    [style]="{'width': '100%'}" />
                <p-dropdown 
                    [options]="ratingOptions" 
                    [(ngModel)]="selectedRating" 
                    optionLabel="label" 
                    placeholder="Rating: All" 
                    [showClear]="true"
                    (onChange)="applyFilters()" 
                    [style]="{'width': '100%'}" />
            </div>
            @if(filteredList.length == 0){
                    No data Found
            }

            <div *ngFor="let cleaner of filteredList; let i = index" (click)="bookingStart(cleaner)">
                <div class="result-col">
                    <div class="image-container">
                        <img src="../../../assets/img/cleaning.jpg" alt="Cleaning Company Image" class="hc-start-image">
                        <div class="hc-start-price" (click)="bookingStart(cleaner)">Booking from ${{cleaner?.minPrice | number:'1.2-2'}}</div>
                    </div>
                    <div class="hc-detail" >
                        <div>
                            <div class="hc-rating"><i class="icon-rating"></i> {{cleaner?.reviewCounter?.rate}} Rating</div>
                            <div>
                                <div class="hc-name">{{cleaner?.firstName +' '+ cleaner?.lastName}}</div>
                                <div class="hc-address">{{cleaner?.address}}</div>
                            </div>
                        </div>
                        <div class="hc-services">
                            <div class="tags-label" *ngIf="cleaner?.servicesOffer">Services we offer:</div>
                            <div class="hc-service-tags">
                                @for (service of cleaner?.servicesOffer?.split(','); track $index) {
                                    <span>{{service}}</span>
                                }
                                <div *ngIf="cleaner?.servicesOffer?.split(',').length > 4">
                                    <button type="button" (click)="toggleServices()" class="btn btn-link">
                                      {{ showAll ? 'Less' : 'More' }}
                                    </button>
                                  </div>
                            </div>
                        </div>
                        <div class="hc-start-price" (click)="bookingStart(cleaner)">Booking from ${{cleaner?.minPrice | number:'1.2-2'}}</div>
                    </div>
                </div>
            </div>
           
            <p-paginator 
                [first]="first"
                [rows]="rows"
                [totalRecords]="filteredList.length"
                (onPageChange)="onPageChange($event)"
                *ngIf="filteredList.length > 10">
            </p-paginator>
        </div>
    </div>
</div>