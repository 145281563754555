<footer class="footer-wrapper">
    <div class="container">
        <div class="footer-wrapper">
            <div class="">
                <h6>Company</h6>
                <ul>
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="">FAQs</a></li>
                    <li><a href="">Terms & Conditions</a></li>
                    <li><a href="">Privacy Policy</a></li>
                </ul>
            </div>

            <div class="">
                <h6>For Provider</h6>
                <ul>
                    <li><a href="#">Partner with us</a></li>
                    <li><a href="#">Help & Support</a></li>
                </ul>
            </div>

            <div>
                <h6>Popular Area</h6>
                <div class="popular-col">
                    <a href="#">Sector-27, Chandigarh</a>
                    <a href="#">Sector-28, Chandigarh</a>
                    <a href="#">Sector-29, Chandigarh</a>
                    <a href="#">Sector-30, Chandigarh</a>
                    <a href="#">Sector-31, Chandigarh</a>
                    <a href="#">Sector-32, Chandigarh</a>
                    <a href="#">Sector-33, Chandigarh</a>
                    <a href="#">Sector-34, Chandigarh</a>
                    <a href="#">Sector-35, Chandigarh</a>
                    <a href="#">Sector-36, Chandigarh</a>
                    <a href="#">Sector-37, Chandigarh</a>
                    <a href="#">Sector-38, Chandigarh</a>
                    <a href="#">Sector-39, Chandigarh</a>
                    <a href="#">Sector-40, Chandigarh</a>
                    <a href="#">Sector-41, Chandigarh</a>
                    <a href="#">Sector-42, Chandigarh</a>
                </div>
            </div>
        </div>

        <div class="copyright">
            <div>©2024 Copyright helpify all Rights Reserved</div>
            <div class="social-media">
                <a href="#"><i class="icon-facebook"></i></a>
                <a href="#"><i class="icon-instagam"></i></a>
            </div>
        </div>
    </div>
</footer>